import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import axios from 'axios';

Vue.mixin({
  methods: {
    formatPrice(value, currency = 'đ', fixed = true) {
      if (value !== undefined && value !== null) {
        let val = (value / 1).toString().replace('.', ',');
        if (fixed) {
          val = (value / 1).toFixed(0).replace('.', ',');
        }
        return val.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + currency;
      }
      return 0 + currency;
    },
    formatDate(time, format = 'D/M/YYYY') {
      if (time !== undefined && time !== null) {
        return dayjs(time).locale('vi').format(format);
      }
      return '';
    },
    exportData(requestUrl, params) {
      try {
        axios({
          url: requestUrl,
          method: 'GET',
          responseType: 'blob',
          params,
          timeout: null
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const filename = response.headers['content-disposition']
              .split(';')
              .find((n) => n.includes('filename='))
              .replace('filename=', '')
              .trim();
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch((errors) => {
            return errors;
            // console.log(errors);
          });
      } catch (e) {
        return e;
        // console.log(e);
      }
    }
  }
});
